import React, { useContext, useState, useEffect } from 'react';
import { SearchContext } from '../../store/SearchContext';
import Restaurant from '../Restaurant/index';

function Restaurants() {
    const [data] = useContext(SearchContext);
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        fetch('./rooms.json')
            .then(res => res.json())
            .then(res => {
                let bySpace = res.filter(r => r.capacity >= parseInt(data.adults) + parseInt(data.children))
                setRestaurants(bySpace);
            })
            .catch(err => console.log(err));
    }, [data]);

    if (data.step !== 1) return null;

    return (
        <section>
            {restaurants && restaurants.map((restaurant, i) => <Restaurant key={i} info={restaurant} selected={data.room.id === restaurant.id} />)}
        </section>
    )
}

export default Restaurants
