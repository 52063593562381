import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import BookingSteps from './components/BookingSteps/index';
import RestaurantSteps from './components/RestaurantSteps/index';
import Confirmation from './components/Confirmation/index';
import Contact from './components/Contact/index';
import NotFound from './components/NotFound/index';
import DefaultLayout from './layouts/default/index';
import BookingRoutes from './routes/booking-routes';
import MainRoutes from './routes/main-routes';
import Register from './components/Register/index';
import Login from './components/Login/index';

function App() {
  return (
    <Router>
      <Switch>
        <BookingRoutes path="/" exact component={BookingSteps} />
        <BookingRoutes path="/appartements" exact component={BookingSteps} />
        <BookingRoutes path="/restaurants" exact component={RestaurantSteps} />
        <MainRoutes path="/contact" exact component={Contact} />
        <MainRoutes path="/register" exact component={Register} />
        <MainRoutes path="/login" exact component={Login} />


        <Route>
          <DefaultLayout>
            <NotFound />
          </DefaultLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
