import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/index';
import Header from '../../components/Header/index';
import Reservation from '../../components/Reservation/index';
import Search from '../../components/Search/index';
import { DiscountProvider } from '../../store/DiscountContext';
import { RoomProvider } from '../../store/RoomContext';
import { SearchProvider } from '../../store/SearchContext';
import './style.scss';

function MainLayout({ children }) {
    const [discount, setDiscount] = useState(0);
    const path = useLocation().search;



    return (
        <DiscountProvider initVal={discount}>
            <SearchProvider>
                <RoomProvider>
                    <div className='app'>
                        <Header />
                        <div className='container'>
                            <div className='row'>
                                <main className='col-md-12'>
                                    {children}
                                </main>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </RoomProvider>
            </SearchProvider >
        </DiscountProvider>
    )
}

export default MainLayout
