import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchContext } from '../../store/SearchContext';

function Contact() {
    let history = useHistory();



    return (
        <div className='vh-100 d-flex justify-content-center flex-column text-center dark-blue'>
            <h1>Contact, Support!</h1>
            <h2>You can contact us using phone number or use this form </h2>
            <div className='p-2'>
                <input name='firstname' type='text' placeholder='First Name' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='lastname' type='text' placeholder='Last Name' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='email' type='email' placeholder='Email' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='phone' type='text' placeholder='Phone' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <textarea name='message' placeholder='Message' css='form-item' style={{ width: '100%' }} ></textarea><br />
            </div>
            <div className='p-2'>
                <button onClick={ev => history.push('/')} className='btn btn-primary my-3'>Contact Now</button>
            </div>
        </div>
    )
}

export default Contact;
