import React from 'react';
import './styles.scss'

function Footer() {
    return (
        <footer className='footer mt-5'>
            <ul className='d-flex flex-wrap justify-content-center align-items-center py-4'>
                <li>
                    <img src='/images/logo_mobile.png' alt='Cocos' height='36' />
                </li>
                <li>
                    <span className='ico ico-logo'></span>
                </li>
                <li>
                    <a href='/terms'>Terms &amp; Conditions</a>
                </li>
                <li>
                    <a href='/policy'>Privacy Policy</a>
                </li>
                <li>
                    <a href='/partners'>Partners</a>
                </li>
                <li>
                    <a href='mailto:reservations@im-money.com' className='dark-blue'>reservations@im-money.com</a>
                </li>
                <li >
                    <a href='tel:+213 550 111 000' className='dark-blue'>TEL: +213 550 111 000</a>
                </li>
                <li>
                    <div className='ico'></div>
                </li>
            </ul>
        </footer>
    )
}

export default Footer
