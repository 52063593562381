import React, { useContext } from 'react';
import { SearchContext } from '../../store/SearchContext';
import { useHistory } from 'react-router-dom';

function Register() {
    const [data, dispatch] = useContext(SearchContext);
    let history = useHistory();

    const { step } = data;

    return (
        <div className='vh-100 d-flex justify-content-center flex-column text-center dark-blue'>
            <h1>Register, Form!</h1>
            <div className='p-2'>
                <input name='firstname' type='text' placeholder='First Name' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='lastname' type='text' placeholder='Last Name' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='email' type='email' placeholder='Email' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='password' type='password' placeholder='Password' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='phone' type='text' placeholder='Phone' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <button onClick={ev => history.push('/')} className='btn btn-primary my-3'>Register Now</button>

                <button onClick={ev => history.push('/Login')} className='btn btn-primary my-3 ml-2'>Login Now</button>
                <div className='d-flex justify-content-between mt-3'>


                </div>
            </div>
        </div>

    );


}

export default Register;