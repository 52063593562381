import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

function Login() {
    let history = useHistory();

    return (
        <div className='vh-100 d-flex justify-content-center flex-column text-center dark-blue'>
            <h1>Login, Form!</h1>

            <div className='p-2'>
                <input name='email' type='email' placeholder='Email' css='form-item' style={{ width: '100%' }} /><br />
            </div>
            <div className='p-2'>
                <input name='password' type='password' placeholder='Password' css='form-item' style={{ width: '100%' }} /><br />
            </div>

            <div className='p-2'>
                <button onClick={ev => history.push('/')} className='btn btn-primary my-3'>Login Now</button>
            </div>
        </div>
    )
}

export default Login;